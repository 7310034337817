<template>
    <div class="flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="episodeFormId"
            :headers="headers"
            :items="tableData"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="clientList"
            no-data-text="No current assessments found, start a new assessment"
            @click:row="rowClickHandler"
        >
            <template v-slot:item.name="{ item }">
                <div class="flex items-center py-2">
                    <TypeIcon
                        class="w-12 mr-4 inline-block text-primary-light hideSmallTablet"
                        :rowData="item.form"
                        svgColour="text-primary-light"
                    />
                    {{ `${item.name}` }}
                </div>
            </template>
            <template class="" v-slot:item.updatedAt="{ item }">
                {{ getFormattedDate(item.updatedAt) }}
            </template>
            <template class="" v-slot:item.status="{ item }">
                <div :class="getStatusColour(item.status)" class="text-xs font-bold">
                    {{ getStatus(item.status) }}
                </div>
            </template>
            <template class="" v-slot:item.dueDate="{ item }">
                {{ getFormattedDate(item.dueDate) }}
            </template>
            <template class="" v-slot:item.dateCompleted="{ item }" @click.stop.prevent>
                <div class="pr-2">
                    {{ getFormattedDate(item.dateCompleted) }}
                </div>
            </template>
            <template class="" v-slot:item.hasScores="{ item }" @click.prevent>
                <div
                    v-if="item.allScores && item.allScores.length > 0"
                    class="pr-2 h-full w-full"
                    @click.prevent
                >
                    <v-btn
                        depressed
                        data-cy="showScores"
                        color="primary"
                        class="mr-2 v-btn--flat inline mt-2"
                        type="button"
                        @click.prevent="showScore(item)"
                    >
                        Scores
                    </v-btn>
                </div>
            </template>
            <template class="" v-slot:item.percentDone="{ item }" @click.prevent>
                <div class="flex items-center" @click.prevent>
                    <v-progress-linear
                        class="rounded-full w-40"
                        :value="item.percentDone"
                        height="12"
                        background-color="primary"
                        color="primary"
                    >
                    </v-progress-linear>
                    <div class="ml-2 uppercase text-xs mr-1 text-grey-2 font-bold inline">
                        {{ `${item.percentDone || 0}` }} %
                    </div>
                </div>
            </template>
            <template class="" v-slot:item.hasAllocatedPoints="{ item }" @click.prevent>
                <div v-if="canAddPoints(item)" class="pr-2 h-full w-full" @click.prevent>
                    <v-btn
                        depressed
                        data-cy="showScores"
                        color="primary"
                        class="mr-2 v-btn--flat inline mt-2"
                        type="button"
                        :disabled="assigningPointsForRow(item)"
                        @click.prevent="assignPoints(item)"
                    >
                        <fa-icon
                            v-if="assigningPointsForRow(item)"
                            icon="spinner"
                            spin
                            class="mr-1"
                        />
                        {{ assignPointsText(item) }}
                    </v-btn>
                </div>
                <div
                    v-else-if="item.hasAllocatedPoints"
                    class="pl-3 h-full w-full mt-6"
                    @click.prevent
                >
                    <fa-icon icon="check" class="mr-2 text-green" />
                </div>
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="grid grid-cols-3" :class="{ 'active-tr': item.active }">
                    <div
                        v-if="canEditRow(item)"
                        class="mx-2 col-start-1"
                        @click.stop.prevent="editEpisodeForm(item)"
                    >
                        <fa-icon icon="pencil-alt" class="mr-2 text-primary-light" />
                    </div>
                    <a
                        href="#"
                        class="hover:text-primary mx-2 col-start-2"
                        v-if="$can('delete', 'episode_form')"
                        @click.stop.prevent="deleteEpisodeForm(item)"
                    >
                        <fa-icon icon="trash-alt" class="text-grey-2 ml-3" />
                    </a>
                    <a
                        href="#"
                        class="hover:text-primary mx-2 col-start-3"
                        @click.prevent="rowClickHandler(item)"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { toRefs, reactive } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { format } from 'date-fns'
import TypeIcon from '@/components/partials/TypeIcon'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import StatusBadge from '@/components/partials/StatusBadge'
import { mapActions, mapState } from 'vuex'
import { SharedMethods } from '@/components/shared/mixins/sharedMixins'
import { userService } from '@/services/user.service'
import { DEFAULT_POINTS_TYPE } from '@/utils/constants'
import { episodeFormService } from '@/services/episode-form.service'

const { useState } = createNamespacedHelpers('app')

export default {
    name: 'FormTable',
    mixins: [HttpMixin, MessageDialog, BackendHelpers, SharedMethods],
    components: { StatusBadge, TypeIcon },
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        searchTerm: {
            type: String,
            default: ''
        },
        filter: {
            type: Object,
            default: () => {}
        },
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emptyText: 'No clients found for the search criteria',
            accreditationStatus: [
                { value: 'OPEN', label: 'OPEN', text: 'OPEN' },
                { value: 'CLOSED', label: 'CLOSED', text: 'CLOSED' }
            ],
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            headers: [
                { text: 'Name', align: 'left', value: 'name', class: 'name--header' },
                { text: 'Status', align: 'left', value: 'status', class: 'status--header' },
                {
                    text: 'Progress',
                    align: 'left',
                    value: 'percentDone',
                    class: 'progress--header'
                },
                { text: 'Due Date', align: 'left', value: 'dueDate', class: 'due--header' },
                { text: 'Completed by', align: 'left', value: 'completedBy', class: 'due--header' },
                {
                    text: 'Date completed',
                    align: 'left',
                    value: 'dateCompleted',
                    class: 'due--header'
                },
                { text: 'Scores', align: 'left', value: 'hasScores', class: 'due--header' },
                {
                    text: 'Points',
                    align: 'left',
                    value: 'hasAllocatedPoints',
                    class: 'due--header'
                },
                { text: '', align: 'left', value: 'editRecord', fixed: true }
            ]
        }
    },
    methods: {
        ...mapActions(['openModal']),
        rowClickHandler(item) {
            if (this.buttonClicked) return
            // console.log('edit item click handler')
            // this.$store.commit('SET_CURRENT_EPISODE', item.episode)
            const formSetId = item.formSetId
            const formAnswerId = item?.formAnswerId
            // First we go and create the formAnswerId, then we redirect to the route
            this.$emit('go-to-form-answer', {
                episodeFormId: item.episodeFormId,
                formSetId,
                formAnswerId,
                clientId: this.clientId,
                name: item.name,
                episodeId: this.currentEpisode?.episodeId,
                tenantId: item.tenantId,
                title: item.name,
                dueDate: item.dueDate
            })
        },
        filterChangeHandler(filters) {
            // first we get the key and there should only be one at this stage
            let filter = { ...this.filter },
                key = Object.keys(filters)[0]
            if (filters[key] && filters[key].length === 0) {
                if (filter.columnFilters[key] != null) {
                    filter.columnFilters[key] = null
                }
            } else {
                filter.columnFilters = { ...this.filter.columnFilters, ...filters }
            }
            this.$emit('filter-change', ...[filter, true])
        },
        isRowLocked(row) {
            // if we don't have the user yet then just return false
            if (!this.$auth.user) return true

            let isSurveyor = false
            let surveyVisit = this.getSurveyVisit(row)
            if (surveyVisit) {
                isSurveyor =
                    surveyVisit.surveyVisitors.find(
                        (sv) => sv.user && sv.user.id === this.$auth.user.userId
                    ) != null
            } else {
                isSurveyor = this.isSurveyor(row)
            }
            console.log(
                'is row locked:',
                row.status === 'PAYMENT_PENDING' &&
                    isSurveyor &&
                    !this.isAdmin &&
                    this.isPracticeManager(row)
            )

            return (
                row.status === 'PAYMENT_PENDING' &&
                isSurveyor &&
                !this.isAdmin &&
                !this.isPracticeManager(row)
            )
        },
        rowClassName({ row }) {
            if (this.isRowLocked(row)) {
                return 'locked-row'
            }
            return 'cursor-pointer'
        },
        getStatus(status) {
            switch (status?.toUpperCase()) {
                case 'COMPLETED':
                    return 'COMPLETED'
                default:
                    return 'INCOMPLETE'
            }
        },
        getStatusColour(status) {
            switch (status?.toUpperCase()) {
                // case 'INCOMPLETE':
                case 'COMPLETED':
                    return 'text-primary-light'
                default:
                    return 'text-yellow'
            }
        },
        editEpisodeForm(item) {
            this.openModal({
                payload: {
                    episodeForm: { ...item },
                    keepOpen: true
                },
                modalName: 'ModalEditEpisodeForm'
            })
        },
        deleteEpisodeForm(item) {
            this.openModal({
                payload: {
                    episodeForm: { ...item },
                    keepOpen: true
                },
                modalName: 'ModalDeleteEpisodeForm'
            })
        },
        canEditRow(item) {
            return (
                this.$can('edit', 'date_completed') &&
                (this.getFormattedDate(item.dateCompleted) ||
                    item.status?.toUpperCase() === 'COMPLETED')
            )
        },
        showScore(item) {
            this.buttonClicked = true
            this.openModal({
                payload: {
                    scores: [...(item.allScores || [])],
                    item: { ...item },
                    keepOpen: true,
                    modalClass: 'large-modal'
                },
                modalName: 'ModalShowScore'
            })
        }
    },
    computed: {
        ...mapState({
            staticChoices: (state) => state.app.staticChoices,
            clientId: (state) => state.app.clientId,
            currentEpisode: (state) => state.app.currentEpisode,
            isModalOpen: (state) => state.app.modal.isVisible
        })
    },
    watch: {
        isModalOpen() {
            if (!this.isModalOpen) this.buttonClicked = false
        },
        tableData: {
            handler() {
                console.log('table data', this.tableData)
            },
            deep: true
        }
    },
    setup(_, { emit }) {
        const { clientId, loggedInUser } = useState(['clientId', 'loggedInUser'])

        const state = reactive({
            buttonClicked: false,
            assigningPoints: false,
            currentItem: null
        })

        const assigningPointsForRow = (item) => {
            return state.assigningPoints && state.currentItem?.episodeFormId === item.episodeFormId
        }

        const assignPointsText = (item) => {
            return state.assigningPoints && state.currentItem.episodeFormId === item.episodeFormId
                ? 'Assigning points...'
                : 'Assign points'
        }

        const canAddPoints = (item) => {
            // TODO:
            console.log(
                '====== can we add points?',
                item.status?.toUpperCase() === 'COMPLETED' &&
                    item.percentDone === 100 &&
                    !item.hasAllocatedPoints
            )
            return (
                item.status?.toUpperCase() === 'COMPLETED' &&
                item.percentDone === 100 &&
                !item.hasAllocatedPoints
            )
        }

        const assignPoints = async (item) => {
            state.buttonClicked = true
            state.assigningPoints = true
            console.log('add points', item)
            state.currentItem = item
            // TODO: build the points object to send to be BE
            // AND get the formType for the formset
            console.log('====== data for thing', clientId.value, item?.formType?.type, item)

            const data = {
                date: format(new Date(), 'yyyy-MM-dd'),
                time: format(new Date(), 'HH:mm'),
                sign: '',
                type: DEFAULT_POINTS_TYPE,
                points: null,
                description: '',
                addedById: loggedInUser.value?.userId
            }
            try {
                const points = await userService.addPointsForAssessment(
                    clientId.value,
                    item.formType?.type,
                    data
                )
                // now we need to set this row as having points
                await episodeFormService.markAllocated(item.episodeFormId)
                emit('refresh-table')
            } catch (error) {
                console.error(error)
            } finally {
                state.assigningPoints = false
                state.buttonClicked = false
                state.currentItem = null
            }
        }

        return {
            ...toRefs(state),
            canAddPoints,
            assignPoints,
            assignPointsText,
            assigningPointsForRow
        }
    }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"episodeFormId","headers":_vm.headers,"items":_vm.tableData,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"data-cy":"clientList","no-data-text":"No current assessments found, start a new assessment"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center py-2"},[_c('TypeIcon',{staticClass:"w-12 mr-4 inline-block text-primary-light hideSmallTablet",attrs:{"rowData":item.form,"svgColour":"text-primary-light"}}),_vm._v(" "+_vm._s(("" + (item.name)))+" ")],1)]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.updatedAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-xs font-bold",class:_vm.getStatusColour(item.status)},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.dueDate))+" ")]}},{key:"item.dateCompleted",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.dateCompleted))+" ")])]}},{key:"item.hasScores",fn:function(ref){
var item = ref.item;
return [(item.allScores && item.allScores.length > 0)?_c('div',{staticClass:"pr-2 h-full w-full",on:{"click":function($event){$event.preventDefault();}}},[_c('v-btn',{staticClass:"mr-2 v-btn--flat inline mt-2",attrs:{"depressed":"","data-cy":"showScores","color":"primary","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.showScore(item)}}},[_vm._v(" Scores ")])],1):_vm._e()]}},{key:"item.percentDone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center",on:{"click":function($event){$event.preventDefault();}}},[_c('v-progress-linear',{staticClass:"rounded-full w-40",attrs:{"value":item.percentDone,"height":"12","background-color":"primary","color":"primary"}}),_c('div',{staticClass:"ml-2 uppercase text-xs mr-1 text-grey-2 font-bold inline"},[_vm._v(" "+_vm._s(("" + (item.percentDone || 0)))+" % ")])],1)]}},{key:"item.hasAllocatedPoints",fn:function(ref){
var item = ref.item;
return [(_vm.canAddPoints(item))?_c('div',{staticClass:"pr-2 h-full w-full",on:{"click":function($event){$event.preventDefault();}}},[_c('v-btn',{staticClass:"mr-2 v-btn--flat inline mt-2",attrs:{"depressed":"","data-cy":"showScores","color":"primary","type":"button","disabled":_vm.assigningPointsForRow(item)},on:{"click":function($event){$event.preventDefault();return _vm.assignPoints(item)}}},[(_vm.assigningPointsForRow(item))?_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.assignPointsText(item))+" ")],1)],1):(item.hasAllocatedPoints)?_c('div',{staticClass:"pl-3 h-full w-full mt-6",on:{"click":function($event){$event.preventDefault();}}},[_c('fa-icon',{staticClass:"mr-2 text-green",attrs:{"icon":"check"}})],1):_vm._e()]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"grid grid-cols-3",class:{ 'active-tr': item.active }},[(_vm.canEditRow(item))?_c('div',{staticClass:"mx-2 col-start-1",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editEpisodeForm(item)}}},[_c('fa-icon',{staticClass:"mr-2 text-primary-light",attrs:{"icon":"pencil-alt"}})],1):_vm._e(),(_vm.$can('delete', 'episode_form'))?_c('a',{staticClass:"hover:text-primary mx-2 col-start-2",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteEpisodeForm(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"trash-alt"}})],1):_vm._e(),_c('a',{staticClass:"hover:text-primary mx-2 col-start-3",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
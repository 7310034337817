import camelcaseKeys from 'camelcase-keys'
import { APP_API_V2, REST_API } from '@/utils/constants'

import { getInstance } from '@/auth'
import axiosInstance from '@/utils/axios'

export class EpisodeFormService {
    constructor() {
        this.formsUrl = `${APP_API_V2}/forms`
    }

    async markAllocated(episodeFormId) {
        let url = `${this.formsUrl}/${episodeFormId}/mark-allocated`
        const { data } = await axiosInstance.patch(
            url,
            {},
            {
                headers: {
                    Authorization: `Bearer ${getInstance().token}`
                }
            }
        )
        return camelcaseKeys(data)
    }

}

export const episodeFormService = new EpisodeFormService()
